@font-face {
  font-family: 'BRHendrix';
  src: local('BRHendrix'),
    url('./fonts/BRHendrix-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 900;
  src: local('BRHendrix'), url('./fonts/BRHendrix-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 700;
  src: local('BRHendrix'), url('./fonts/BRHendrix-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 600;
  src: local('BRHendrix'),
    url('./fonts/BRHendrix-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 500;
  src: local('BRHendrix'),
    url('./fonts/BRHendrix-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 300;
  src: local('BRHendrix'), url('./fonts/BRHendrix-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 200;
  src: local('BRHendrix'),
    url('./fonts/BRHendrix-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'BRHendrix';
  font-weight: 100; 
  src: local('BRHendrix'), url('./fonts/BRHendrix-Thin.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: #7a7786;
}

.react-date-picker__wrapper {
  border: 0;
}
/* variables */
